<template>
  <div id="app">
    <van-icon v-if="$route.path != '/' && $route.path != '/about'" @click="$router.back()"
      style="position: fixed;left: 0.5rem;top: 0.5rem;" name="arrow-left" />
    <van-tabbar v-model="active" route
      v-if="$route.path !== '/enterfault' && $route.path !== '/entermaintenance' && $route.path !== '/toconfirm'">
      <van-tabbar-item icon="home-o" replace to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o" replace to="/about">我的</van-tabbar-item>
    </van-tabbar>
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue';
import { Tabbar, TabbarItem, Icon } from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);

export default {
  data() {
    return {
      active: 0,
    };
  },
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
