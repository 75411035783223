<template>
  <div class="home">
    <!-- 切换车间 -->
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
    </van-dropdown-menu>
    <!-- 车间图片展示 -->
    <van-image width="100%" height="10rem" :src="operationSheet[value1]" />
    <!-- 设备故障闭环 -->
    <div class="operation_sheet_title">设备故障闭环</div>
    <van-grid :column-num="1" :gutter="10">
      <!-- <van-badge :content="10" v-for="item, i in operationSheet" :key="i"> -->
      <van-grid-item @click="toNewRouter(0)">
        <span style="font-size: 12px;">{{ '录入故障单' }}</span>
      </van-grid-item>
      <!-- </van-badge> -->
      <van-badge :content=waitConfirmNum>
        <van-grid-item @click="toNewRouter(1)">
          <span style="font-size: 12px;">{{ '确认故障单' }}</span>
        </van-grid-item>
      </van-badge>
      <van-badge :content="waitMaintenanceNum">
        <van-grid-item @click="toNewRouter(2)">
          <span style="font-size: 12px;">{{ '待维修故障单' }}</span>
        </van-grid-item>
      </van-badge>
      <!-- <van-badge :content="10" v-for="item, i in operationSheet" :key="i"> -->
      <van-grid-item @click="toNewRouter(3)">
        <span style="font-size: 12px;">{{ '历史故障单' }}</span>
      </van-grid-item>
      <!-- </van-badge> -->
    </van-grid>
  </div>
</template>

<script>
import Vue from 'vue'

import { Grid, GridItem, Image as VanImage, Badge, DropdownMenu, DropdownItem,Notify } from 'vant';
import dd from 'dingtalk-jsapi'

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(Badge);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Notify);
export default {
  data() {
    return {
      operationSheet: [require('../assets/4line.png'), require('../assets/2gw.png')],
      waitConfirmNum: 0,
      waitMaintenanceNum: 0,
      value1: 0,
      option1: [
        { text: '上饶晶科8车间', value: 0 },
        { text: '美国车间', value: 1 },
      ],
    };
  },
  methods: {
    toNewRouter(i) {
      console.log(i);
      if (i === 0) {
        this.$router.push('/enterfault')
      } else if (i === 1) {
        this.$router.push('/faultlist')
      } else if (i === 2) {
        this.$router.push('/maintenancelist')
      } else if (i === 3) {
        this.$router.push('/history')
      }
    },
    getDDcode() {
      if (dd.env.platform !== 'notInDingTalk') {
        dd.getAuthCode({
          corpId: 'dingad716ec89c8623b5f2c783f7214b6d69',
          success: (res) => {
            this.getUser(res['code'])
            console.log(res);
          },
          fail: () => {
            Notify('获取失败')
            // this.username = '获取失败'
          },
          complete: () => { },
        });
      }
      // else {
      //   // this.username = '不在钉钉环境';
      //   sessionStorage.setItem('username', '不在钉钉环境')
      // }

    },
    getUser(code) {
      this.$axios
        .get("http://124.222.100.16:80/python/UserData/", { params: { code: code } })
        .then(
          (response) => {
            // this.username = response['res']['name']
            console.log(response.data);
            sessionStorage.setItem('username', response.data['res']['name'])
          },
          (error) => {
            // this.username = '获取失败'
            Notify('获取失败')
            console.log(error.message);
          }
        );
    }
  },
  created() {
    if (!JSON.parse(sessionStorage.getItem('faultData'))) {
      console.log(0);
      sessionStorage.setItem('faultData', JSON.stringify([{ "id": 1, "info": "8车间A区-1线-组框机1 2023/11/06 00:00已持续11分钟线体气缸上位异常", "isConfirm": true }, { "id": 2, "info": "8车间A区-2线-组框机2 2023/11/07 00:00已持续23分钟长边1边框归正紧位异常", "isConfirm": false }, { "id": 3, "info": "8车间A区-1线-组框机1 2023/11/06 00:00已持续11分钟线体气缸上位异常", "isConfirm": false }]))
    }
    if (!JSON.parse(sessionStorage.getItem('maintenanceData'))) {
      sessionStorage.setItem('maintenanceData', JSON.stringify([{ "id": "1", "info": "8车间A区-1线-组框机1 2023/11/06 00:00已持续11分钟线体气缸上位异常", "maintenancePersonnel": "王子寒", "isRepair": false }]))
    }
    // this.getDDcode()
  },
  mounted() {
    this.waitConfirmNum = JSON.parse(sessionStorage.getItem('faultData')).filter(item => item.isConfirm == false).length == 0 ? ' ' : JSON.parse(sessionStorage.getItem('faultData')).filter(item => item.isConfirm == false).length
    this.waitMaintenanceNum = JSON.parse(sessionStorage.getItem('maintenanceData')).filter(item => item.isRepair == false).length == 0 ? ' ' : JSON.parse(sessionStorage.getItem('maintenanceData')).filter(item => item.isRepair == false).length
  }
}
</script>

<style lang="scss" scoped>
.home {
  background-color: #f7f8fa;
  height: 100vh;
  padding: 1.25rem 0.625rem;
  box-sizing: border-box;

  .van-image {
    padding: 0 0.625rem;
    box-sizing: border-box;
  }

  .operation_sheet_title {
    height: 3.125rem;
    line-height: 3.125rem;
  }

  ::v-deep .van-grid-item__content--center {
    width: calc(100vw - 2.5rem);
  }

  ::v-deep .van-dropdown-menu__bar {
    background-color: #f7f8fa;
    box-shadow: none;
  }

  ::v-deep .van-dropdown-menu__item {
    justify-content: flex-start;

  }

  ::v-deep .van-badge--fixed {
    // margin-bottom: .625rem;
    top: unset;
    right: .625rem;
    bottom: 1.875rem;
    z-index: 9;
  }
}
</style>