import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/enterfault',
    name: 'EnterFault',
    component: () => import('../views/EnterFault.vue')
  },
  {
    path: '/maintenancelist',
    name: 'MaintenanceList',
    component: () => import('../views/MaintenanceList.vue')
  },
  {
    path: '/entermaintenance',
    name: 'EnterMaintenance',
    component: () => import('../views/EnterMaintenance.vue')
  },
  {
    path: '/faultlist',
    name: 'FaultList',
    component: () => import('../views/FaultList.vue')
  },
  {
    path: '/toconfirm',
    name: 'ToConfirm',
    component: () => import('../views/ToConfirm.vue')
  }, {
    path: '/success',
    name: 'Success',
    component: () => import('../components/enter/SuccessPage.vue')
  }, {
    path: '/issuccess',
    name: 'iSSuccess',
    component: () => import('../components/confirm/SuccessPage.vue')
  }, {
    path: '/maintenancesus',
    name: 'MaintenanceSus',
    component: () => import('../components/maintenance/MaintenanceSus.vue')
  },{
    path: '/history',
    name: 'history',
    component: () => import('../views/FaultHistory.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
